import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import './assets/css/mobile/global.css';
import './assets/css/mobile/shopping.css';
import { useDispatch, useSelector } from 'react-redux';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import SidebarWithelabel from './components/common/structure/whitelabel/sidebar/Sidebar';
import Sidebar from './components/common/structure/sidebar/Sidebar';
import Content from './components/common/structure/Content';
import TopNavigation from './components/common/structure/header/Header';
import { isMobile } from 'react-device-detect';

import store from './store';

import { logout, revokeToken } from './store/modules/auth/actions';
import { setCurrentCustomerId } from './store/modules/general/actions';
import { openSocketRequest } from './store/modules/chat/actions';
import api from './services/api';

import OfflineComponent from './components/offline/index';
import ChatLoading from './components/chat/content/chat/ChatLoading';
import { SessionExpiredModal } from './components/SessionExpiredModal/SessionExpiredModal.component';

import LoadScriptsExternal from './lib/ScriptsExternal';

import LoginPage from './pages/auth/login';
import AuthPage from './pages/auth/index';
import RecoverPasswordPage from './pages/auth/recoverPassword';
import NewPasswordPage from './pages/auth/newPassword';

import { requestLoginSuccess } from './store/modules/auth/actions';
import { requestWhiteLabelConfig } from './store/modules/whitelabel/actions';

import poliAuthTokenGet, {
  poliGetUserIDFromLocalStorage,
} from './utils/poliAuthTokenGet';
import poliConfig from './utils/poliConfig';
import { DispatchEvent } from './utils/dispatchEvent';
import globalVars from './utils/globalVars';
import Animation from './components/common/animation';
import { useWhiteLabel } from './hooks/useWhiteLabel';

import { CustomerContactCreditProvider } from './context/CustomerContactCredit/provider';
import Changelog from './components/common/changelog'
import MobileMenu from './components/mobile/menu/MobileMenu.component';
import { ContactPanelMobile } from './components/mobile/contactPainelMobile/index';

function initGeneral() {
  const urlParams = new URLSearchParams(window.location.search);

  if (store) {
    let replaceURL = false;

    if (urlParams.has('navigator_id_internal')) {
      localStorage.setItem(
        'navigator_id_internal',
        urlParams.get('navigator_id_internal')
      );
    }
    if (urlParams.has('chat')) {
      replaceURL = true;
      let chat_id = urlParams.get('chat');
      localStorage.setItem('chat_id', parseInt(chat_id));
    }
    if (urlParams.has('lang')) {
      replaceURL = true;
      localStorage.setItem('lg', urlParams.get('lang'));
    }
    if (replaceURL && window.history && window.history.replaceState) {
      if (window.location.pathname.substr(-8, 8) === 'shopping') {
        window.history.replaceState({ foor: 'shopping' }, 'Pay', '/shopping');
      } else {
        window.history.replaceState({ foor: 'chat' }, 'Chat', '/chat');
      }
    }
  }
}

function App() {
  let history = useHistory();

  const [showModalSessionExpired, setShowModalSessionExpired] = useState();

  const wlConfig = useSelector((state) => state?.whitelabel?.config);

  const authUser = useSelector((state) => state.auth.access);
  const state_current_customer_id = useSelector(
    (state) => state.general.current_customer_id
  );
  const changelogState = useSelector((state) => state.changelog.open);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  // Inicia o script do embed-crm em uma camada muito a cima, para não duplicar a chamada do script em cada chamada de componente
  useEffect(() => {
    // URL do script a partir da env
    const url = process.env.REACT_APP_EMBED_CRM_URL;

    // Adiciona o script ao DOM
    const script = document.createElement('script');
    script.src = `${url}/embed-crm-v3.js`;
    script.async = true;

    // Adiciona o script ao body
    document.body.appendChild(script);

    // Remove o script ao desmontar o componente
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  /**
   * Logica Css personalizado Whitelabel
   */
  const whitelabel = useWhiteLabel();
  let routerLocation = useLocation();

  /**
   * Valida se está na url de login, se está utlizando url de login do wl, e se o wl possui uma url de login personalizada, caso tenha redireciona para a url cadastrada.
   */

  const keepAliveSession = async () => {
    try {
      await api.keepAlive();

      // modal tiver aberto fechar pois laravel esta vivo e com sessao
      if (showModalSessionExpired) {
        setShowModalSessionExpired(false);
      }
    } catch (e) {
      // 401 Sessão expirada
      if (e?.response?.status == 401 && !showModalSessionExpired) {
        dispatch(revokeToken());
        setShowModalSessionExpired(true);
      }
    }
  };

  // Limpar parametros da URL
  const cleanParamsUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    if (
      window.location.search?.toString()?.length > 0 &&
      urlParams.has('current_customer_id')
    ) {
      const title = `Chats`;
      const url = window.location.pathname;
      window.history.pushState({}, title, url);
    }
  };

  //Primeira rodada
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    let canGetAccessFromStorage = true;
    let stopLoading = false;

    if ((routerLocation?.pathname == '/login' && urlParams.has('force_login')) ||
        routerLocation?.pathname == '/auth') {
      //Quando por algum motivo não tem sessao no laravel, mas tem sessao no spa
      canGetAccessFromStorage = false;
      // console.log("logout from /login and force_login");
      store.dispatch(logout({ logout_laravel: false }));
      if (window.keepAliveInterval) clearInterval(window.keepAliveInterval);
    }

    if (canGetAccessFromStorage) {
      try {
        let access = JSON.parse(window.localStorage.getItem('poli_access'));
        if (access) {
          dispatch(requestLoginSuccess(access));
        } else {
          stopLoading = true; //Não tem sessao ativa, exibe area nao autenticada
        }
      } catch (e) {
        console.log(e, 'catch');
      }
    } else {
      stopLoading = true;
    }

    localStorage.setItem('@polichat/menuRetratil', true);
    if (isMobile) {
      document.querySelector('#root').classList.add('isMobile');
    }

    dispatch(requestWhiteLabelConfig());

    if (stopLoading) setLoading(false);
  }, []);

  useEffect(() => {
    if (window._poli) {
      (function (c, l, a, r, i, t, y) {
        c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
        t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
        y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);

        clarity("set", "userId", window._poli.id);
        clarity("set", "customerId", window._poli.account.id);
      })(window, document, "clarity", "script", "fqo9f8ulad");
    }
  }, []);

  useEffect(() => {
    if (authUser && !window.lockSessionStart) {
      window.lockSessionStart = true;
      //Mantem a sessão do laravel ativa e xsrf token on browser
      keepAliveSession();
      window.keepAliveInterval = setInterval(keepAliveSession, process.env.REACT_APP_TIME_TO_ALIVE || 60000);

      const urlParams = new URLSearchParams(window.location.search);
      let storage_current_customer_id = parseInt(
        window.localStorage.getItem('spa_current_customer_id')
      );
      let current_customer_id = null;

      //Empresa corrente logada. Preferencia para o que vem na url devido laravel passar ao trocar de empresa
      if (urlParams.has('current_customer_id')) {
        let url_current_customer_id = parseInt(
          urlParams.get('current_customer_id')
        ); //isso é para respeitar a sessão do laravel, que ainda é utilizada pr algumas funcionalidades

        /**
         * Se tiver o custumer_id no token de autenticação, ele sera setado no state
         */
        if (authUser?.user?.customers?.includes(url_current_customer_id)) {
          current_customer_id = url_current_customer_id;
        } else {
          /**
           * Se não achar a empresa, usuario sera deslogado
           */
          window.lockSessionStart = false;
          history.push('/logout');
        }
      }

      /**
       * Se tiver encontrado a empresa da url, mas tiver custumer_id do localstorage dentro do token de autenticação
       */
      if (!current_customer_id && storage_current_customer_id) {
        if (authUser?.user?.customers?.includes(storage_current_customer_id)) {
          current_customer_id = storage_current_customer_id;
        }
      }

      /**
       * Se no final ainda nao tiver encontrado a empresa da url, teremos que pega o primeira empresa no token de autenticação
       */
      if (!current_customer_id && Array.isArray(authUser?.user?.customers)) {
        window.lockSessionStart = false;
        current_customer_id = authUser.user.customers[0];
      }

      /**
       * Se encontrou a empresa da url
       * setar no stage
       */
      if (current_customer_id) {
        store.dispatch(setCurrentCustomerId(current_customer_id));
      }

      /**
       * Limpar parametros da URL apos iniciado a seção
       */
      if (window.lockSessionStart) cleanParamsUrl();
    }
    if(whitelabel.scriptInjection !== ''){//criação de variável global para ser usada em scripts injetados
      globalVars.setUser({...authUser?.user, token:authUser?.token})
      DispatchEvent("auth",window.Poli.user)
    }
  }, [authUser]);

  useEffect(() => {
    if (authUser && state_current_customer_id && !window.lockSocketStart) {
      window.lockSocketStart = true;
      //Abre a conexao com o websocket, algumas páginas precisam dos dados que vem do websocket. Abrir a conexao após ter os dados do usuário e a empresa corrente logada
      store.dispatch(openSocketRequest());

      if (loading) setLoading(false);
    }
  }, [authUser, state_current_customer_id]);

  useEffect(() => {
    if(whitelabel.scriptInjection && whitelabel.scriptInjection!=='' && !document.querySelector("#script_wl")){
      const scriptTagInjection = document.createElement('script');
      scriptTagInjection.type = 'text/javascript';
      scriptTagInjection.id = 'script_wl';
      scriptTagInjection.async = true;
      scriptTagInjection.innerHTML = ` ///Script Injection - Whitelabel Poli\\\
      ${whitelabel.scriptInjection}
      ///end - Script Injection - Whitelabel Poli\\\ `;
      document.head.appendChild(scriptTagInjection);
    }
  }, [whitelabel.scriptInjection])

  initGeneral();

  if (loading || !wlConfig || !wlConfig.app_title)
    return (
      <Loading />
    );

  return (
    <>
      <div className={"mainContainer " + (changelogState ? 'darken' : '')} >
        <OfflineComponent />
        <LoadScriptsExternal isAuthArea={authUser} />

        {authUser ? (
          <AuthArea
            routerLocation={routerLocation}
            showModalSessionExpired={showModalSessionExpired}
            isWl={whitelabel.isWl}
          />
        ) : (routerLocation?.pathname == '/auth') ? (
          <InterfacelessArea
            routerLocation={routerLocation}
          />
        ) : (
          <NotAuthArea
            routerLocation={routerLocation}
          />
        )}
      </div>
      {changelogState && <Changelog />}
    </>
  )
}

const Loading = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <Animation
        icon="loading"
        size={50}
        style={{
          border: '6px solid transparent',
          borderColor: 'var(--background-spinner)',
          borderTopColor: 'var(--green-polichat)',
        }}
      />
    </div>
  );
};

const styles = {
  application: {
    backgroundColor: 'var(--secondary-background-color)',
  },
};

const AuthArea = ({ routerLocation, showModalSessionExpired, isWl }) => {
  const isWhiteLabel = useSelector((state) =>
    state?.whitelabel?.config?.is_white_label
  );

  const isLoginPage = routerLocation?.pathname == '/login';
  const isLogoutPage = routerLocation?.pathname == '/logout';

  if (isLogoutPage && !window.lockForcedLogout) {
    window.lockForcedLogout = true;
    store.dispatch(logout());
    if (window.keepAliveInterval) clearInterval(window.keepAliveInterval);
  }

  // Redirecionar para o laravel apos login
  if (isLoginPage) {
    if (isWl) {
      window.location.assign('/chat');
      return;
    }

    if (process.env.REACT_APP_USE_VALHALLA === 'true') {
      window.location.assign(
        `${
          process.env.REACT_APP_VALHALLA_URL
        }/home?access=${poliAuthTokenGet()}&user=${poliGetUserIDFromLocalStorage()}`
      );
    } else {
      window.location.assign(`${poliConfig.poliAppLaravelUrl}/home`);
    }
  }

  if (isLoginPage || isLogoutPage) {
    return (
      <div style={styles.application} className="contentContainer">
        <div className="bodyContentContainer">
          <ChatLoading />
        </div>
      </div>
    );
  }

  return (
    <>
      <SessionExpiredModal showModal={showModalSessionExpired} />
      <CustomerContactCreditProvider>
        {isMobile && (
          <>
            <MobileMenu />
            <ContactPanelMobile />
          </>
        )}

        { isWhiteLabel ?
          (
            <div
              style={styles.sidebar}
              className={
                isMobile ? 'sidebarContainer hasMobileMenu' : 'sidebarContainer'
              }
            >
              <SidebarWithelabel />
            </div>
          )
            :
          (
            <div
              style={styles.sidebar}
              className={
                isMobile ? 'sidebarContainer hasMobileMenu' : 'sidebarContainer'
              }
            >
              <Sidebar isMobile={isMobile} />
            </div>
          )
        }


        {isWl && (
          <div className="topNavigationContainer">
            <TopNavigation />
          </div>
        )}

        <div style={styles.application} className="contentContainer">
          <ToastContainer autoClose={5000} theme="colored" icon={false} />
          <Content />
        </div>
      </CustomerContactCreditProvider>
    </>
  );
};

const NotAuthArea = () => {
  return (
    <Switch>
      <Route key={1} path={'/login'} component={LoginPage} />;
      <Route
        key={2}
        path={'/recover-password'}
        component={RecoverPasswordPage}
      />
      ;
      <Route key={3} path={'/new-password'} component={NewPasswordPage} />;
      <Redirect exact={true} path={'/'} to="/login" />
      <Redirect path="*" to="/login" />
    </Switch>
  );
};

const InterfacelessArea = () =>{
  return (
    <Switch>
      <Route key={0} path={'/auth'} component={AuthPage} />
    </Switch>
  );
}

export default App;
